<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-links">
        <div class="footer-links-contact">
          <a class="text-18-500" :href="`tel:${phone}`">
            {{ phone }}
          </a>
          <a class="text-18-500" :href="`mailto:${email}`">
            {{ email }}
          </a>
          <ul class="footer-links-contact__media">
            <li
              v-for="socialNetwork in socialNetworks"
              :key="socialNetwork.value"
            >
              <a :href="socialNetwork.link" target="_blank">
                <BaseIcon :icon="socialNetwork.value" />
              </a>
            </li>
          </ul>
        </div>
        <ul class="footer-links__app">
          <li v-for="store in appLinks" :key="store.icon">
            <a :href="store.link" target="_blank">
              <BaseIcon :icon="store.icon" />
            </a>
          </li>
        </ul>
      </div>

      <div class="footer-nav">
        <div class="footer-nav__group">
          <LocLink to="/companies" class="text-18-600">
            {{ $t("For Companies") }}
          </LocLink>
          <LocLink to="/partners" class="text-18-600">
            {{ $t("For Partners") }}
          </LocLink>
        </div>
        <div class="footer-nav__group">
          <LocLink to="/facilities" class="text-18-600">
            {{ $t("Facilities") }}
          </LocLink>
          <LocLink to="/levels" class="text-18-600">
            {{ $t("Memberships") }}
          </LocLink>
          <LocLink to="/contacts" class="text-18-600">
            {{ $t("Contacts") }}
          </LocLink>
        </div>
        <div class="footer-nav__group">
          <LocLink to="/license" class="text-18-600">
            {{ $t("Documents for legal entities") }}
          </LocLink>
          <LocLink to="/user-agreements" class="text-18-600">
            {{ $t("User agreements") }}
          </LocLink>
        </div>
      </div>

      <div class="footer-info">
        <div class="footer-info__official">
          <div class="text-14-500">{{ $t("footer.rights") }}</div>
          <div class="text-14-500">{{ $t("footer.brand_info") }}</div>
          <LocLink
            v-if="brand === Brand.allsports"
            to="/providing-payment-service-rules"
            class="text-14-500 footer-info__link"
          >
            {{
              $t(
                "Payment service provider registration number 112.193190172.0-0-0-4-0-0-0-0-0"
              )
            }}
          </LocLink>
        </div>
        <div class="footer-info__build">
          <div class="text-14-500">Build: {{ system.build }}</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useConfigStore } from "~/stores/config";
import { Brand } from "~/types/entities";
import BaseIcon from "~/components/icons/BaseIcon.vue";
import LocLink from "~/components/lang/LocLink.vue";
import { useContentStore } from "~/stores/content";

const { brand } = useRuntimeConfig().public;
const configStore = useConfigStore();
const { system } = storeToRefs(configStore);

const contentStore = useContentStore();
const {
  getSocialNetworks: socialNetworks,
  getAppLinks: appLinks,
  getContacts: officeContact,
} = storeToRefs(contentStore);

const { phone, email } = officeContact.value;
</script>
