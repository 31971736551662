<script setup lang="ts">
import TheHeader from "~/components/page/TheHeader.vue";
import TheFooter from "~/components/page/TheFooter.vue";

import { successModalKey } from "~/symbols";
import { SuccessModalTypes } from "~/types/entities";
import {
  GET_AN_OFFER_MODAL_ID,
  useGetAnOfferFormModal,
} from "~/composables/modals/getAnOfferFormModal/useGetAnOfferFormModal";

const { offerFormModalState, showModal } = useGetAnOfferFormModal();

const FACILITIES_PAGE = "facilities";

const route = useRoute();
const localePath = useLocalePath();
const { cookiePolicyKey } = useRuntimeConfig().public;

const mountGetAnOfferModal = ref(false);
const mountSuccessModal = ref(false);
const mountCookieBlock = ref(false);

const isShowCookieBlock = ref(false);
const isShowSuccessModal = ref(false);
const successModalType = ref(SuccessModalTypes.CONNECT_COMPANY);
function showSuccessModal(type: SuccessModalTypes): void {
  !mountSuccessModal.value && (mountSuccessModal.value = true)
  successModalType.value = type;
  isShowSuccessModal.value = true;
}
provide(successModalKey, showSuccessModal);

const isShowFooter = computed(() => route.path !== localePath(FACILITIES_PAGE));

const mainClass = computed(() => {
  return {
    "main-padding-bottom": !route.fullPath.includes(FACILITIES_PAGE),
  };
});

watch(() => route.query.modal, (newValue) => {
  newValue === GET_AN_OFFER_MODAL_ID &&
    !mountGetAnOfferModal.value &&
    (mountGetAnOfferModal.value = true);
}, {immediate: true});

onMounted(() => {
  if (!getCookie(cookiePolicyKey)) {
    mountCookieBlock.value = true;
    isShowCookieBlock.value = true;
  }
});
</script>

<template>
  <div id="defaultView" class="main-bg">
    <div id="anchor-top"></div>
    <TheHeader />
    <main class="main" :class="mainClass">
      <slot />
    </main>
    <TheFooter v-if="isShowFooter" />
    <LazyModalsSuccessModal
      v-if="mountSuccessModal"
      v-model:is-show-modal="isShowSuccessModal"
      :modal-type="successModalType"
    />
    <LazyCookieBlock
      v-if="mountCookieBlock"
      v-model:is-show-modal="isShowCookieBlock"
    />
    <LazyModalsGetAnOfferFormModal
      v-if="mountGetAnOfferModal"
      :is-show-modal="offerFormModalState.isModalOpen"
      :cooperation-type="offerFormModalState.cooperationType"
      @closeModal="showModal(false)"
    />
  </div>
</template>
